import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './ForgotPasswordForm.module.scss';
import { ReqStatus, ValidationResult } from '../types';
import * as validation from '../services/validationService';
import Input from './form/Input';
import Button from './Button';
import Link from './Link';
import routes from '../routes';
import Alert from './Alert';
import { forgotPassword } from '../apiClients/userClient';
import Box from './layout/Box';

type Props = {
  className?: string;
  onSubmitSuccess?(): void;
  done?: boolean;
};

const ForgotPasswordForm = ({ className, done }: Props) => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    general: '',
    email: '',
  });
  const [submitStatus, setSubmitStatus] = useState<ReqStatus>();

  const isLoading = submitStatus === 'pending';

  function onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newEmail = e.target.value;
    if (errors.email) {
      validateEmail(newEmail);
    }

    setEmail(newEmail);
  }

  function validateEmail(val: string): ValidationResult {
    const result = validation.validateEmail(val);

    setErrors((state) => ({
      ...state,
      email: result.hasErr ? result.errMsg : '',
    }));

    return result;
  }

  async function onFormSubmit(e: React.FormEvent) {
    e.preventDefault();

    const emailValidation = validateEmail(email);
    if (emailValidation.hasErr) {
      return;
    }

    setSubmitStatus('pending');
    setErrors((state) => ({
      ...state,
      general: '',
    }));

    // The redirect from the django reset flow is not followed, so manually redirect when successful
    const res = await forgotPassword({ email });
    if (res.err) {
      setSubmitStatus('err');
      setErrors((state) => ({
        ...state,
        general: 'An error occurred and your request was not submitted. Please try again.',
      }));

      return;
    }

    history.replace({ pathname: routes.FORGOT_PASSWORD_DONE.path });
  }

  const loginLink = (
    <Link variant="underline" href={routes.LOGIN.path} useRouter>
      Back to log in
    </Link>
  );

  if (done) {
    return <Box mt="0.8rem">{loginLink}</Box>;
  }

  return (
    <form noValidate onSubmit={onFormSubmit} className={className}>
      <Input
        id="forgotPasswordEmail"
        type="email"
        autoComplete="email"
        fullWidth
        label="Email"
        value={email}
        onChange={onEmailChange}
        onBlur={() => validateEmail(email)}
        disabled={isLoading}
        error={errors.email}
      />

      {errors.general && (
        <Alert className={styles.errAlert} title="Error" type="error">
          {errors.general}
        </Alert>
      )}

      <Button className={styles.submitBtn} type="submit" color="primary" disabled={isLoading}>
        Continue
      </Button>
      <div className={styles.linkWrapper}>{loginLink}</div>
    </form>
  );
};

export default ForgotPasswordForm;

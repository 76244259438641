import React from 'react';
import loadable from '@loadable/component';

import AccountFormLayout from '../../components/layout/AccountFormLayout';
import Page from '../../components/Page';
import Text from '../../components/Text';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import { useFetchCsrfToken } from '../../hooks/utilHooks';
import { Theme } from '@material-ui/core';

const ForgotGoose = loadable(() => import('../../media/geese/question-goose.svg'));

type Props = {
  done?: boolean;
};

const ForgotPassword = (props: Props) => {
  useFetchCsrfToken();

  return (
    <Page
      title="Forgot Password | Goosechase"
      description="Forgotten your password? Enter your e-mail address below and we'll e-mail you instructions to set a new one."
      hideNavbar
      hideFooter
      noIndex={props.done}
    >
      <AccountFormLayout>
        <Text el="h1" variant="headingMd">
          {props.done ? 'Email sent!' : 'Reset password'}
        </Text>
        <Text el="p" variant="paragraphMd" css={{ marginBottom: '4rem' }}>
          {props.done
            ? "If there is an account with that email, you will receive instructions to reset your password. If the email doesn't show up soon, please check your spam folder."
            : "No need to ruffle your feathers. Enter your email and we'll send you instructions to reset your password."}
        </Text>
        <ForgotPasswordForm done={props.done} />

        <AccountFormLayout.RightFixedContent css={{ padding: '96px', backgroundColor: '#E4FFA1' }}>
          <ForgotGoose width="33.2rem" />
        </AccountFormLayout.RightFixedContent>
      </AccountFormLayout>
    </Page>
  );
};

export default ForgotPassword;
